document.addEventListener( 'DOMContentLoaded', function () {
	new Splide('.slider--content', {
		perPage: 3,
		type: 'loop',
		// padding: "5rem",
		gap: '2rem',
		autoplay: true,
		pagination: false,
		autoheight: true,
		// fixedWidth: '28rem',
		arrowPath: "M3.4,16.6h24.9L17.6,5.8c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0L39,17.6c0.2,0.2,0.3,0.3,0.4,0.5 c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.4,0.1,0.7s0,0.5-0.1,0.7 c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.3,0.4-0.4,0.5L22.4,39 c-0.7,0.7-1.5,1-2.4,1s-1.8-0.3-2.4-1c-1.3-1.3-1.3-3.5,0-4.8l10.7-10.7H3.4C1.5,23.4,0,21.9,0,20S1.5,16.6,3.4,16.6z",
		// rewind: true
		breakpoints: {
			'840': {
				perPage: 2
			},
			'640': {
				perPage: 1
			}
		}
	} ).mount();
} );