let currentSlide = 0;

const prevSlide = () => { 
    const slide = document.querySelectorAll('.rezension')
    const num = slide.length - 1
    if (currentSlide === 0) {
        currentSlide = num
        showSlide(slide)
    } else { 
        currentSlide--
        showSlide(slide)
    }
}
const nextSlide = () => { 
    const slide = document.querySelectorAll('.rezension')
    const num = slide.length - 1
    if (currentSlide >= num) {
        currentSlide = 0
        showSlide(slide)
    } else { 
        currentSlide++
        showSlide(slide)
    }
}

const showSlide = (el) => {
    const offset = currentSlide * -100
    el.forEach(slide => slide.style.transform = `translateX(calc(${offset}% - 1rem))`)
}
 
const slideShow = () => { 
    setInterval(nextSlide, 5000)
}

// let isDragging = false



// const drag = (m) => {
//     // isDragging && console.log('dragging');
//     const slides = document.querySelectorAll('.rezension')
//     slides.forEach(slide => { 
//         if(isDragging) slide.style.transform = `translateX(calc(${currentSlide * -100 - m}% - 1rem))`
//     })
// }
 
// const slider = document.querySelector('.rezensionen')
// let xPos

// slider.addEventListener('mousedown', e => {
//     console.log(isDragging);
//     xPos = e.clientX
//     isDragging = true
// })

// slider.addEventListener('mousemove', ev => {       
//     isDragging && console.log(isDragging);
//     let moved = (xPos - ev.clientX) / 10;
//     isDragging && console.log(moved);
//     isDragging && drag(moved)
// })


// slider.addEventListener('mouseup', e => {
//     isDragging = false
//     console.log(isDragging);
//     xPos = 0
//  })

if (document.getElementById('kundenzufriedenheit')) { 
   slideShow()
}