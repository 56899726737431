// NAVIGATION

const mainNav = document.querySelector('.main--nav')
const toggleStickyNav = () => { 
    const navOffset = mainNav.offsetTop
    if (window.pageYOffset > navOffset) {
        document.body.classList.add('sticky')
        document.body.style.paddingTop = mainNav.offsetHeight + 'px'
        // console.log('active');
    } else { 
        document.body.classList.remove('sticky')
        document.body.style.paddingTop = 0
    }
} 
window.addEventListener('scroll', toggleStickyNav)
window.addEventListener('resize', toggleStickyNav)


if (document.querySelector('[data-bg-scroll]')) { 
    const scrollElements = document.querySelector('[data-bg-scroll]')
    const getElementScrollOffset = el => { 
        const elementTop = el.getBoundingClientRect().top
        const windowHeight = window.innerHeight
        if (windowHeight > elementTop) {
            el.style.backgroundPositionY = `${elementTop / 10}%`
        }
    }
    window.addEventListener('scroll', e => {        
        getElementScrollOffset(scrollElements)
    })
}
if (document.querySelector('[data-bg-scroll-top]')) { 
    const scrollElementsTop = document.querySelector('[data-bg-scroll-top]')
    const getElementScrollOffsetTop = el => { 
        const scrollTop = window.scrollY
            el.style.backgroundPositionY = `${30 - scrollTop / 10}%`

    }
    window.addEventListener('scroll', e => {        
        getElementScrollOffsetTop(scrollElementsTop)
    })
}