const state = {
    index: 1,
    frageIndex: 1,
    ergebnis: {},
    fragen: [
        {
            frage: 'Für wen suchen Sie ein Hörgerät?',
            typFrage: false,
            options: [
                {
                    type: 'radio',
                    value: 'Für mich selbst',
                    label: 'Für mich selbst',
                    ansprache: 'ego'
                },
                {
                    type: 'radio',
                    value: 'Für mein Kind',
                    label: 'Für mein Kind'
                },
                {
                    type: 'radio',
                    value: 'Für meine Eltern',
                    label: 'Für meine Eltern'
                },
                {
                    type: 'radio',
                    value: 'Für meine Großeltern',
                    label: 'Für meine Großeltern'
                },
                {
                    type: 'radio',
                    value: 'Sonstige',
                    label: 'Sonstige'
                },
             
            ]
        },
        {
            frage: 'Hat die betreffende Person schon mal moderne Hörsysteme ausprobiert?',
            frage_ego: 'Haben Sie schon mal moderne Hörsysteme ausprobiert?',
            typFrage: false,
            options: [
                {
                    type: 'radio',
                    value: 'Ja',
                    label: 'Ja'
                },
                {
                    type: 'radio',
                    value: 'Nein',
                    label: 'Nein, noch nicht'
                },
            ]
        },
        {
            frage: 'Trägt die betreffende Person bereits ein Hörsystem?',
            frage_ego: 'Tragen Sie bereits ein Hörsystem?',
            typFrage: false,
            options: [
                {
                    type: 'radio',
                    value: 'Ja',
                    label: 'Ja',
                },
                {
                    conditional: true,
                    type: 'radio',
                    value: 'Nein',
                    label: 'Nein'
                },
            ]
        },
        {
            frage: 'Wie alt sind die aktuellen Hörsysteme',
            frage_ego: 'Wie alt sind Ihre aktuellen Hörsysteme',
            conditional: true,
            typFrage: true,
            options: [
                {
                    type: 'radio',
                    value: '< 1 Jahr',
                    label: '< 1 Jahr',
                },
                {
                    type: 'radio',
                    value: '2 Jahre',
                    label: '2 Jahre',
                },
                {
                    type: 'radio',
                    value: '3 Jahre',
                    label: '3 Jahre',
                },
                {
                    type: 'radio',
                    value: '4 Jahre',
                    label: '4 Jahre',
                },
                {
                    type: 'radio',
                    value: '> 5 Jahre',
                    label: '> 5 Jahre',
                },
                
            ]
        },
        {
            frage: 'Ist die betreffende Person älter als 55 Jahre?',
            frage_ego: 'Sind Sie älter als 55 Jahre?',
            typFrage: true,
            options: [
                {
                    type: 'radio',
                    value: 'Ja',
                    label: 'Ja',
                },
                {
                    type: 'radio',
                    value: 'Nein',
                    label: 'Nein, noch nicht',
                }
                
            ]
        },
        {
            frage: 'In welchem Postleitzahlengebiet wohnt die betreffende Person?',
            frage_ego: 'In welchem Postleitzahlengebiet wohnen Sie?',
            typFrage: true,
            button: 'next',
            validate: 'validatePlz()',
            
            options: [
                {
                    type: 'text',
                    value: 'Plz eingeben',
                    label: 'Plz eingeben',
                    id: 'plz',
                },
                
            ]
        },
        {
            frage: 'Bitte tragen Sie Ihren Namen ein, sodass wir einen Rückruf-Termin vereinbaren können.',
            button: 'next',
            validate: 'validateAnrede()',
            
            options: [
                {
                    type: "group",
                    group: [
                        {
                            type: 'base-radio',
                            value: 'Frau',
                            label: 'Frau',
                        },
                        {
                            type: 'base-radio',
                            value: 'Herr',
                            label: 'Herr',
                        },
    
                    ]
                },
                {
                    type: 'text',
                    value: 'Vorname',
                    label: 'Vorname',
                    id: 'name',
                },
                {
                    type: 'text',
                    value: 'Nachname',
                    label: 'Nachname',
                    id: 'nachname',
                },
                
            ]
        },
        {
            frage: 'Bitte geben Sie eine Telefon&shy;nummer an unter der wir Sie erreichen können.',
            button: 'submit',
            validate: 'validateEmail()',
            options: [
                
                {
                    type: 'text',
                    value: 'Telefonnummer',
                    label: 'Telefonnummer',
                    id: 'telefon'
                },
                {
                    type: 'email',
                    value: 'E-Mail',
                    label: 'E-Mail',
                    id: 'email'
                },
                {
                    type: 'checkbox',
                    value: 'Datenschutz',
                    label: '<span style="text-align: left; font-size: 14px">Ich habe die <a style="font-size: inherit" href="https://www.optiker-bode.de/datenschutz" target="_blank">Datenschutzbestimmungen</a> gelesen und stimme diesen zu.</span>',
                },
                
            ]
        },
    ],
}

if (document.querySelector('#form--content')) {
    


const generateForm = elementID => {
    const formContainer = document.getElementById(elementID)
    let output = "";
    state.fragen.map((s, i) => {
      
        output += `<fieldset class="${(i + 1) === state.index && 'active'}"><strong data-id="${i + 1}">${s.frage}</strong>`
        s.options.map((o, index) => { 
            
            // Wenn Typ ist Radio
            if (o.type === 'radio') { 
                let dataConditional = ""
                let dataAnsprache = ""
                let eventHandler
                if (o.ansprache) {
                    eventHandler = `onclick="nextQuestionEgo(this, ${i})"`
                } else {
                    if(i === 0 ) eventHandler = `onclick="nextQuestionNoEgo(this, ${i})"`
                    else eventHandler = `onclick="nextQuestion(this, ${i})"`

                }
                
                output += `
                <div class="radio frage-${i + 1}">
                    <input ${o.conditional ? 'data-conditional="true"' : ''} type="radio" ${eventHandler} name="q${i + 1}" id="q${i+1}-${index + 1}" value="${o.value}">
                    <label for="q${i+1}-${index + 1}">${o.label}</label>
                </div>`
            }
            // Wenn Typ Text
            if (o.type === 'text') {
                output += `
                <input type="text" id="${o.id}" name="${o.id}" placeholder="${o.label}">
                <div class="error" id="err-${o.id}"></div>
                `
            }
            if (o.type === 'email') {
                output += `
                <input type="email" id="${o.id}" name="${o.id}" placeholder="${o.label}">
                <div class="error" id="err-${o.id}"></div>
                `
            }
            // Wenn Typ Checkbox
            if (o.type === 'checkbox') {
                output += `
                    <div class="checkbox">
                        <input type="checkbox" id="${o.value}" required>
                        <label for="${o.value}">${o.label}</label>
                    </div>`
            }
            // Wenn Typ Gruppe
            if (o.type === 'group') {
                output += `<div class="radio--group">`
                o.group.map((g,j) => { 
                    output += `
                        <div class="radio--element">
                            <input type="radio" id="group-${i + 1}-${j}" name="radio-${i}" value="${g.value}">
                            <label for="group-${i + 1}-${j}">${g.label}</label>
                        </div>
                    `
                })
                output += `</div>`
            }
        })
        if (s.button) {
            if (s.button === 'next') { 
                output += `<div class="next--button" onclick="${s.validate}">Weiter</div>`
            }
            if (s.button === 'submit') { 
                output += `<button class="next--button" onclick="validateSubmit(event)">Absenden</button>`
            }
         }
        output += `</fieldset>`
    })
    formContainer.innerHTML = output
    showBullets('bullets')
}

const setConditional = () => { 
    state.index++
    state.ergebnis.conditional = true
}
    

const showBullets = (id) => { 
    const bulletContainer = document.getElementById(id)
    state.fragen.map((f, i) => { 
        if (i + 1 <= state.index) {
            bulletContainer.innerHTML += `<div class="bullet active" onclick="showQuestion(this, ${i})"></div>`
        } else { 
            bulletContainer.innerHTML += `<div class="bullet" onclick="showQuestion(this, ${i})"></div>`
        }

    })
}

const updateBullets = () => {
    const bullets = document.querySelectorAll('.bullet')
    bullets.forEach(b => b.classList.remove('active'))
    for (let i = 0; i < state.index; i++){
        bullets[i].classList.add('active')        
    }
}

    const nextQuestion = (el) => {
        

        state.index++
        updateAnsprache('3')
    if (el && el.dataset.conditional) { 
        state.index++
        state.ergebnis.conditional = true
    }
  
    const fieldsets = document.querySelectorAll('fieldset')
    fieldsets.forEach(f => f.classList.remove('active'))
    fieldsets[state.index - 1].classList.add('active')
    console.log(state.ergebnis);
    updateBullets()
}
    const updateAnsprache = (type) => { 
        
        const headlines = document.querySelectorAll('[data-id]')
        headlines.forEach(headline => { 
            const id = parseInt(headline.dataset.id)
            if (id < headlines.length - 1) { 
                if (type === 'ego') {                    
                    headline.innerText = state.fragen[id - 1].frage_ego ? state.fragen[id - 1].frage_ego : state.fragen[id - 1].frage
                }
                if (type === 'noego') {                    
                    headline.innerText = state.fragen[id - 1].frage
                }
                
            }
        })

    }
	const nextQuestionEgo = (el, num) => {
		dataLayer.push({ 'event': `Frage ${num + 1}: ${el.value}` })
		console.log(dataLayer);
        state.ergebnis.ansprache = true
        state.index++
        updateAnsprache('ego')
  
    const fieldsets = document.querySelectorAll('fieldset')
    fieldsets.forEach(f => f.classList.remove('active'))
    fieldsets[state.index - 1].classList.add('active')
    // console.log(state.ergebnis);
    updateBullets()
}
	const nextQuestionNoEgo = (el, num) => {
		dataLayer.push({ 'event': `Frage ${num + 1}: ${el.value}` })
		console.log(dataLayer);
        state.ergebnis.ansprache = false
        state.index++
        updateAnsprache('noego')
  
    const fieldsets = document.querySelectorAll('fieldset')
    fieldsets.forEach(f => f.classList.remove('active'))
    fieldsets[state.index - 1].classList.add('active')
    // console.log(state.ergebnis);
    updateBullets()
}

const showQuestion = (el, index) => {
    state.index = index + 1
    let classes = el.classList
    classes.forEach(c => {
        if (c === 'active') { 
            const fieldsets = document.querySelectorAll('fieldset')
            fieldsets.forEach(f => f.classList.remove('active'))
            fieldsets[index].classList.add('active')
        }
    })
} 

const validatePlz = () => {
    const plz = document.getElementById('plz').value
    const errContainer = document.getElementById('err-plz')
    if (plz.length === 5 && !isNaN(plz)) {
        nextQuestion()
        errContainer.innerHTML = ""
    } else { 
        errContainer.innerHTML = "<strong>Bitte gültige Postleitzahl angeben!</strong>"
    }
}
const validateAnrede = () => {
    const name = document.getElementById('nachname').value
    const anrede = document.querySelector('[name="radio-6"]:checked')
    const errContainer = document.getElementById('err-nachname')
    console.log(anrede);
if (name.length > 2 && /\d/.test(name) === false && anrede) {
    nextQuestion()
    errContainer.innerHTML = ""
} else { 
    errContainer.innerHTML = "<strong>Bitte Anrede und Namen eingeben!</strong>"
}
}
const validateSubmit = (e) => {
    e.preventDefault()

    const form = document.getElementById('termin')
    const telefon = document.getElementById('telefon').value
    const email = document.getElementById('email').value
    const datenschutz = document.getElementById('Datenschutz')
    const errContainer = document.getElementById('err-telefon')
    const errEmail = document.getElementById('err-email')
    const checkTel = /^[0-9()+/– -]+$/;
    const checkMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( (checkTel.test(telefon) === true || telefon.replace(/[^0-9]/g, "").length > 4 ) && datenschutz.checked && checkMail.test(email) === true) {
        form.submit()
        decreaseCounter('.counter', 'bode__counter')
        
    } else { 
        if (!datenschutz.checked) errContainer.innerHTML = "<strong>Bitte akzeptieren Sie die Datenschutzbestimmungen!</strong>"
        if (checkTel.test(telefon) === false  || telefon.replace(/[^0-9]/g, "").length < 4) errContainer.innerHTML = "<strong>Bitte eine gültige Telefonnummer eingeben!</strong>"
        //if (checkMail.test(email) === false) errEmail.innerHTML = "<strong>Bitte eine gültige E-Mail Adresse eingeben!</strong>"
    }

}


generateForm('form--content')
}

