const getPixelCode = (el) => { 
    const container = document.getElementById(el)
    container.innerHTML = `<img src="https://secure.adnxs.com/px?id=1304535&t=2" width="1" height="1" />`
    }


const reloadLocation = () => { 
    location.reload()
}

window.addEventListener('click', e => { 
    console.log(e);
    e.target.id === 'uc-btn-accept-banner' || e.target.dataset.event === 'save-settings' ? reloadLocation() : null
})
